/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
    return (
        //<GoogleReCaptchaProvider reCaptchaKey="6Le7bbYZAAAAAOMlLs0_q0tuaaGw3m3p0_VkipQv">
        <GoogleReCaptchaProvider reCaptchaKey="6LfeY8EZAAAAAETd12Vn48iq5W9r9rJZPYjw8IMO">
            {element}            
        </GoogleReCaptchaProvider>        
    )
}